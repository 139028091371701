(function () {
  'use strict';

  class ProfileCtrl {
    constructor(User, $mdToast, Media) {
      let vm = this;
      vm.ctrlName = 'ProfileCtrl';
      vm.User = User;
      vm.Media = Media;
      vm.$mdToast = $mdToast;
      vm.user = vm.User.getUserCookie().user;
    }
    update(form) {
      const vm = this;
      if (form.$valid) {
        vm.updateUser();
      }
    }
    updateUser() {
      const vm = this;
      vm.User.update(vm.user)
        .then(()=> {
          vm.$mdToast.show(
            vm.$mdToast.simple()
              .textContent('Profile updated')
              .position('bottom right')
              .hideDelay(3000)
          );
        });
    }
    uploadMedia() {
      const vm = this;
      vm.Media.mediaCreate(vm.media.file)
        .then((data)=>{
          vm.user.avatar = data.file.url;
          vm.updateUser();
        });
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.profile.controller:ProfileCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.profile')
    .controller('ProfileCtrl', ProfileCtrl);
}());
